<template>
    <div class="zx-prod-bar">
        <div class="zx-prod-title" id="hz">
            <div class="zx-prod-box-1">
                <div class="zx-prod-box-1-left">
                    <h2>慧治</h2>
                    <div>数据综合治理平台</div>
                    <p>践行数据中台方法论，围绕全领域数据资产盘点、端到端数据资源治理、泛场景要素价值发挥，一站式提供异构集成、规范建模、质量核验、增值加工、安全服务等能力，助力企业打造标准可信、安全可靠、便捷可用的数据体系。</p>
                </div>
                <div class="zx-prod-box-1-right">
                    <img src="../../assets/product/prod-title-right-icon-3.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="zx-prod-cont-bar" >
            <div class="zx-prod-cont-title">
                <h2>核心功能</h2>
            </div>
            <div class="zx-prod-cont-hz-box">
                <div class="zx-prod-cont-hz-list">
                    <img src="../../assets/product/zx-advantage-list-icon-hz-1.png" alt="">
                    <h3>资产目录</h3>
                    <p>围绕全域数据资产的定义和盘点，提供标准规范、结构模型、质量约束、安全策略等核心元数据功能，精确定义数据资源，打通数据血缘网络，实现数据的标准化和资产化管理，搭建数据资产目录中心，支撑全域数据治理、运营和应用</p>
                </div>
                <div class="zx-prod-cont-hz-list">
                    <img src="../../assets/product/zx-advantage-list-icon-hz-2.png" alt="">
                    <h3>数据连接</h3>
                    <p>支持主流数据库的连接、同步，支持可视化拖拽配置、批量整库定时汇聚、变更数据实时同步、限速容错等精细化控制，提升数据集成的效率，打造全域数据中心</p>
                </div>
                <div class="zx-prod-cont-hz-list">
                    <img src="../../assets/product/zx-advantage-list-icon-hz-3.png" alt="">
                    <h3>数据加工</h3>
                    <p>支持SQL、Python、Scala等语言类型的批处理、流处理、流批一体及算法编程能力；提供代码算法调试、语法高亮检查、任务版本管理、场景任务模板等多种开发辅助功能；满足临时查询、在线处理、周期调度等不同场景下的数据增值加工需求</p>
                </div>
                <div class="zx-prod-cont-hz-list">
                    <img src="../../assets/product/zx-advantage-list-icon-hz-4.png" alt="">
                    <h3>数据服务</h3>
                    <ul class="zx-prod-hz-gong">
                        <li>
                            <h4>数据共享交换服务</h4>
                            <span>支持多种模式快速构建、测试和发布API，提供并行、串连、分支等多种策略的API编排能力；支持集成主流微服务治理系统，提供多因子认证、精细化鉴权、多维度流控、场景化降级等微服务治理能力；支持集成慧聚等数据服务平台，快速发布和接入数据服务网络，提供计量计费等服务运营功能</span>
                        </li>
                        <li>
                            <h4>密态协同计算服务</h4>
                            <span>支持集成慧通等隐私计算平台的计算节点，无缝接入数据安全流通网络</span>
                        </li>
                    </ul>
                </div>
                <div class="zx-prod-cont-hz-list">
                    <img src="../../assets/product/zx-advantage-list-icon-hz-5.png" alt="">
                    <h3>作业调度</h3>
                    <p>支持各类数据处理任务的统一调度，灵活配置调度周期；提供可视化任务定义、工作流编排、运行监控、人工干预等调度管理功能；支持线性扩展的分布式调度能力</p>
                </div>
            </div>
        </div>
        <div class="zx-prod-cont-bar" style="height: 770px;box-sizing: border-box">
            <div class="zx-prod-cont-title">
                <h2>应用场景</h2>
            </div>
            <div style="height: 360px; position: relative;width: 1200px">
                <div class="zx-prod-scene-box">
                        <div class="zx-prod-scene-list">
                            <div class="zx-prod-scene-list-content">
                                <h2>数据采集汇聚</h2>
                                <p>通过建设针对企业内外部数据的采集汇聚平台，可以实现企业内外部数据体系有效对接，统一、汇聚多源异构数据，发挥数据融合能力</p>
                            </div>
                        </div>
                        <div class="zx-prod-scene-list">
                            <div class="zx-prod-scene-list-content">
                                <h2>数据加工治理</h2>
                                <p>通过构建数据加工治理平台，实现统一数据资源管理和按需增值加工积累，帮助用户做到对数据资源的全局把控、质量提升、精准萃取、快速使用，构建可信、可用、可达的数据资产</p>
                            </div>
                        </div>
                        <div class="zx-prod-scene-list">
                            <div class="zx-prod-scene-list-content">
                                <h2>数据服务共享</h2>
                                <p>通过建设数据共享服务平台，在数据安全和隐私保护的前提下，依托数据资产管理，提供快速的共享服务构建发布、有效的数据服务管控治理，加速释放数据价值</p>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <div class="zx-prod-cont-bar"  style="height: 900px;">
            <div class="zx-prod-cont-title">
                <h2>平台优势</h2>
            </div>
            <div class="zx-advantage-bar">
                <div class="zx-advantage-box">
                    <div class="zx-advantage-list" style="width: 387px">
                        <img src="../../assets/product/zx-adv-list-icon-hz-1.png" alt="">
                        <h3>全栈数据工具链</h3>
                        <p>贯通数据全生命周期的技术和工具集合，提供覆盖计算、存储、交换、中间件、数据、应用等全栈、多层次的大数据工具</p>
                    </div>
                    <div class="zx-advantage-list" style="width: 387px">
                        <img src="../../assets/product/zx-adv-list-icon-hz-2.png" alt="">
                        <h3>场景化工程能力</h3>
                        <p>支持从数据接入汇聚到数据可视化分析、从批量数据加工作业到实时流式事件处理等场景化工程能力，满足不同场景的数据资源开发、管理与利用需求</p>
                    </div>
                    <div class="zx-advantage-list" style="width: 387px">
                        <img src="../../assets/product/zx-adv-list-icon-hz-3.png" alt="">
                        <h3>开放式系统架构</h3>
                        <p>兼容主流大数据存储和计算引擎，满足探索和利旧需求；分层设计、协议兼容，支持按需定制自有大数据技术栈</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index.vue",
        data(){
            return {
                subType: 'tab1'
            }
        }
    }
</script>

<style scoped lang="scss">
    .zx-prod-title {
        width: 100%;
        background: #F6F8FA;
        height: 560px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .zx-prod-box-1 {
        display: flex;
        width: 1200px;
        justify-content: space-between;
        text-align: left;
        .zx-prod-box-1-left{
            margin-top: 140px;
        }
        h2{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: normal;
            font-size: 48px;
            color: #121212;
            margin-bottom: 24px;
        }
        div{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            color: #305DC4;
            margin-bottom: 34px;
        }
        p{
            width: 490px;
            height: 105px;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 176.02%;
            text-align: justify;
            color: #121212;
        }
    }

    .zx-prod-cont-bar{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 140px;
        .zx-prod-cont-title{
            h2{

                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #121212;
                position: relative;
                margin-bottom: 118px;
                &:after{
                    content: '';
                    position: absolute;
                    width: 70px;
                    height: 6px;
                    background: #305DC4;
                    border-radius: 11px;
                    left: 50%;
                    margin-left: -35px;
                    top: 90px;
                }
            }
        }
    }
    .zx-prod-cont-box{
        width: 1200px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .zx-prod-cont-list{
            width: 600px;
            height: 266px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #305DC4;
            color: #fff;
            padding-left: 30px;
            box-sizing: border-box;
            img{
                padding-top: 53px;
                margin-bottom: 25px;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 176.02%;
                color: #FFFFFF;
                margin-bottom: 5px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 176.02%;
                letter-spacing: 0.09em;
                color: rgba(255, 255, 255, 0.75);
                text-align: left;
            }
            &:nth-child(2){
                background-color: #2D56B6;
                img{
                    padding-top: 53px;
                }
            }
            &:nth-child(3){
                background-color: #2D56B6;
                img{
                    padding-top: 53px;
                }
            }
            &:nth-child(4){
                background-color: #294DA1;
                img{
                    padding-top: 53px;
                }
            }
            &:hover{
                box-shadow: 0px 5px 21px rgba(255, 255, 255, 0.1);
                transition: all .5s;
            }
        }
        .zx-prod-ht-gong{
            display: flex;
            width: 100%;
            justify-content: space-between;
            li{
                width: 340px;
                text-align: left;
                position: relative;
                &:nth-child(2):after,&:nth-child(1):after{
                    content: '';
                    width: 1px;
                    height: 63px;
                    background: rgba(255, 255, 255, 0.7);
                    border-radius: 8px;
                    position: absolute;
                    right: -24px;
                    top: 0;
                }
            }
        }
    }

    .zx-prod-cont-hz-box{
        width: 1200px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .zx-prod-cont-hz-list{
            width: 600px;
            height: 346px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #305DC4;
            color: #fff;
            padding-left: 30px;
            box-sizing: border-box;
            width: 400px;
            &:nth-child(4){
                width: 800px;
            }
            img{
                padding-top: 53px;
                margin-bottom: 25px;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 176.02%;
                color: #FFFFFF;
                margin-bottom: 5px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 176.02%;
                letter-spacing: 0.09em;
                color: rgba(255, 255, 255, 0.75);
                width: 329px;
                text-align: left;
            }
            &:nth-child(1){
                background: #305DC4;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(2){
                background: #2D56B6;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(3){
                background: #294DA1;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(4){
                background: #2A54B6;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(5){
                background: #305DC4;
                img{
                    padding-top: 58px;
                }
            }
            &:hover{
                box-shadow: 0px 5px 21px rgba(255, 255, 255, 0.1);
                transition: all .5s;
            }
        }
        .zx-prod-hz-gong{
            display: flex;
            width: 100%;
            justify-content: space-between;
            li{
                text-align: left;
                position: relative;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 176.02%;
                /* or 25px */
                letter-spacing: 0.09em;
                color: rgba(255, 255, 255, 0.75);
                h4{
                    position: relative;
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 176.02%;
                    color: #FFFFFF;
                    margin-bottom: 4px;
                    padding-left: 10px;
                    &::after{
                        content: '';
                        position: absolute;left: 0;top: 8px;
                        width: 3px;
                        height: 15px;
                        background: #FFFFFF;
                        border-radius: 16px;
                    }
                }
                &:nth-child(1){
                    width: 470px;
                    span{
                        display: block;
                        width: 472px;
                    }
                }
                &:nth-child(2){
                    width: 250px;
                    span{
                        display: block;
                        width: 207px;
                    }
                }
                &:nth-child(1):after{
                    content: '';
                    width: 1px;
                    height: 145px;
                    background: rgba(255, 255, 255, 0.7);
                    border-radius: 8px;
                    position: absolute;
                    right: -24px;
                    top: 0;
                }
            }
        }
    }
    .zx-prod-scene-box{
        width: 1200px;
        position: absolute;
        left: 0;
        display: flex;
        justify-content: space-between;
        .zx-prod-scene-list{
            width: 390px;
            height: 306px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            &:nth-child(1){
                background: url("../../assets/product/zx-hz-csent-1.png") no-repeat 100%;
            }
            &:nth-child(2){
                background: url("../../assets/product/zx-hz-csent-2.png") no-repeat 100%;
            }
            &:nth-child(3){
                background: url("../../assets/product/zx-hz-csent-3.png") no-repeat 100%;
            }
            .zx-prod-scene-list-content{
                position: absolute;
                left: 0;
                top: 240px;
                background: rgba(18, 18, 18, 0.55);
                height: 306px;
                transition: all .5s;
            }
            h2{
                height: 66px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 66px;
                color: #FFFFFF;
                margin-bottom: 5px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 1.76;
                color: #fff;
                text-align: left;
                padding: 0 20px;
            }
            &:hover{
                .zx-prod-scene-list-content{
                    top: 0;
                    transition: all .5s;
                }
            }
        }
    }

    .zx-advantage-bar{
        display: flex;
        align-items: center;
    }
    .zx-advantage-box{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        .zx-advantage-list{
            width: 285px;
            height: 420px;
            background: #FFFFFF;
            border: 1px solid rgba(153, 156, 176, 0.12);
            box-shadow: 0px 4px 24px rgba(153, 156, 176, 0.32);
            border-radius: 4px;
            padding-top: 74px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
                margin-bottom: 40px;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 176.02%;
                color: #121212;
                margin-bottom: 62px;
            }
            p{
                text-align: left;
                padding: 0 20px;
            }
        }
    }

    .zx-prod-sub-nav{
        display: flex;
        justify-content: space-around;
        width: 755px;
        margin-bottom: 90px;
        .zx-prod-sub-nav-list{
            width: 230px;
            text-align: center;
            height: 60px;
            font-size: 34px;
            cursor: pointer;
            position: relative;
            &.cur:after{
                content: '';
                position: absolute;
                width: 230px;
                height: 3px;
                background: #2653B8;
                border-radius: 4px;
                left: 0;
                bottom: -3px;
                transition: all .5s;
            }
        }
    }
</style>
